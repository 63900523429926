<template>
  <div class="page">
    <el-card class="searchContainer">
      <el-form inline>
        <el-form-item label="商品名称">
          <el-input
            v-model="filter.productName"
            placeholder="请输入商品名称"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品货号">
          <el-input
            v-model="filter.productId"
            placeholder="请输入商品货号"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属商家">
          <el-input
            v-model="filter.businessName"
            placeholder="请输入所属商家"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="商家ID">
          <el-input
            v-model="filter.businessId"
            placeholder="请输入商家ID"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form inline>
       <el-form-item label="上架状态">
          <el-select class="searchInput" v-model="filter.shelfStatus" placeholder="请选择上架状态">
            <el-option
              v-for="i in $store.getters['product/basic/shelfStatusList']"
              :key="i.value"
              :label="i.name"
              :value="i.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="库存状态">
          <el-select class="searchInput" v-model="filter.stockStatus" placeholder="请选择库存状态">
            <el-option
              v-for="i in $store.getters['product/basic/stockStatusList']"
              :key="i.value"
              :label="i.name"
              :value="i.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="过期状态">
          <el-select class="searchInput" v-model="filter.expiringStatus" placeholder="请选择过期状态">
            <el-option
              v-for="i in $store.getters['product/basic/expiringStatusList']"
              :key="i.value"
              :label="i.name"
              :value="i.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="init">重置</el-button>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="loading" class="tableContainer">
      <div class="header" slot="header">
        <span class="title">商品列表</span>
        <el-button size="mini" style="float: right" type="success" @click="handleAdd">新建商品</el-button>
        <el-button size="mini" style="float: right; margin-right: 10px" type="success" @click="batchDeliver"
          >批量发货</el-button
        >
      </div>
      <el-table :data="list" fit center>
        <el-table-column prop="id" width="94" label="ID" align="center"> </el-table-column>
        <el-table-column prop="id" label="商品主图" align="center" width="70">
          <template slot-scope="scope">
            <img :src="scope.row.productUrl | thumbMedium" style="width: 50px; height: 50px" />
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="商品信息" align="center" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <div class="productName">
                {{ scope.row.productName }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="商家信息" align="center">
          <template slot-scope="scope">
            <div class="vendorInfo" @click="handleClickBusiness(scope.row.businessId)">
              <div>名称: {{ scope.row.businessTitle }}</div>
              <div>ID：{{ scope.row.businessId }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="areaId" label="地区" align="center" width="50">
          <template slot-scope="scope">
            <span class="title"> {{ scope.row.areaIds | formatManyAreas }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="id" label="sku信息" align="center" width="430">
          <template slot-scope="scope">
            <div class="skuContainer" v-for="(i, n) in scope.row.productSkuList" :key="n">
              <div class="info">
                <div class="label">名称：</div>
                <el-tooltip effect="dark" placement="top" :content="i.skuName">
                  <span
                    style="
                      display: inline-block;
                      width: 90px;
                      vertical-align: middle;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      text-align: start;
                    "
                    class="name"
                  >
                    {{ i.skuName }}
                  </span>
                </el-tooltip>
              </div>
              <div class="info">
                <div class="label">当前库存：</div>
                <div class="value">{{ i.currentStock }}</div>
              </div>
              <div class="info">
                <div class="label">已售：</div>
                <div class="value">{{ i.saleNum }}</div>
              </div>
              <div class="info">
                <div class="label">售价：</div>
                <div class="value">{{ i.salePrice }}</div>
              </div>
              <span
                style="
                  display: inline-block;
                  padding: 3px;
                  line-height: 20px;
                  height: 25px;
                  border-radius: 4px;
                  color: rgb(255, 255, 255);
                  background-color: rgb(25, 117, 255);
                  border-color: rgb(25, 117, 255);
                "
                @click="handleSkuOrder(i.id)"
              >
                订单
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="订单" width="140">
          <template slot-scope="scope">
            <span class="info">待发货： {{ scope.row.productExpressStatusCountVO.waitDeliverCount }}</span>
            <div>已发货： {{ scope.row.productExpressStatusCountVO.hasDeliverCount }}</div>
            <div>已签收： {{ scope.row.productExpressStatusCountVO.hasTakeCount }}</div>
            <div>已退款： {{ scope.row.productExpressStatusCountVO.cancelCount }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="id" label="截止时间" align="center" width="200">
          <template slot-scope="scope">
            <div>上架时间:{{ scope.row.saleTimeStart | formatTime }}</div>
            <div>下架时间:{{ scope.row.saleTimeEnd | formatTime }}</div>
            <div>过期时间:{{ scope.row.writeOffTimeEnd | formatTime }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="上架状态" align="center" width="50">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.switchPublish"
              @change="onStatusChange(scope.row)"
              :inactive-value="0"
              :active-value="1"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="id" label="操作" width="200px" align="center" fixed="right">
          <template slot-scope="{ row }">
            <template v-if="row.requestSourcesIds.includes(1)">
              <el-button @click="handleShowPromoCode(row)" type="text" size="mini">推广码</el-button>
              <el-button @click="handleMiniCode(row)" type="text" size="mini">小程序码</el-button>
            </template>
            <el-button @click="handleCopy(row)" type="text" :disabled="row.disabled" size="mini">复制</el-button>
            <el-button @click="handleEdit(row)" type="text" :disabled="row.disabled" size="mini">编辑</el-button>
            <el-dropdown @command="handleCommand($event, row)" style="margin-left: 10px">
              <el-button type="text" size="small">
                更多
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="workOrder" v-if="row.oaIssueId"><span>查看工单</span> </el-dropdown-item>
                <el-dropdown-item command="cancelLinkToOrder" v-if="row.oaIssueId"
                  ><span>取消关联工单</span>
                </el-dropdown-item>
                <el-dropdown-item command="linkToOrder" v-if="!row.oaIssueId"><span>关联工单</span> </el-dropdown-item>
                <!-- <el-dropdown-item command="h5" v-if="row.requestSourcesIds.includes(2)"
                  ><span>H5码</span>
                </el-dropdown-item> -->

                <el-dropdown-item command="hidden" :disabled="row.disabled">
                  <span> 隐藏设置</span>
                </el-dropdown-item>
                <el-dropdown-item command="del" :disabled="row.disabled"><span>删除</span> </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          :layout="pageLayout"
          :total="page.total"
          :page-sizes="sizeList"
          :page-size="page.size"
          :current-page="page.page"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" :visible.sync="promoData.isShow" title="推广二维码">
      <div style="display: flex">
        <el-form label-width="10em">
          <el-form-item label="公众号">
            <el-select v-model="promoData.wx" @change="onWxAccountChange">
              <el-option v-for="(i, n) in promoData.wxList" :key="n" :value="i.mpAppId" :label="i.mpName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二维码" v-loading="promoData.loading">
            <img style="width: 200px" :src="promoData.url" />
          </el-form-item>
        </el-form>
        <div class="miniUrl">
          <div>小程序页面路径</div>
          <img style="width: 200px" :src="wxQrcode" />
          <div ref="copy">{{ miniUrl }}</div>
          <el-button @click="CopyUrl" type="primary">点击复制</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="商品上架信息导出"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/product/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>

    <!-- 显示隐藏 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="hideStatus.show"
      title="更改商品的显示/隐藏状态"
      width="300px"
    >
      <div style="text-align: center">
        {{ hideStatus.status === 1 ? '你是否要显示这个商品' : '你是否要隐藏这个商品' }}
      </div>

      <div slot="footer">
        <el-button round @click="hideStatus.show = false">取消</el-button>
        <el-button round type="primary" @click="confirmSwitchHideStatus">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="hideVisible" title="更改商品的显示/隐藏状态" width="300px">
      <div style="text-align: center; margin-bottom: 24px">该商品需要在小程序哪些地方隐藏？（多选）</div>
      <el-checkbox-group v-model="hideProductRange">
        <el-checkbox :label="item.value" v-for="item in selectAreas" :key="item.value">{{ item.name }}</el-checkbox>
      </el-checkbox-group>

      <div slot="footer">
        <el-button round @click="hideVisible = false">取消</el-button>
        <el-button round type="primary" @click="handleProductArea">确定</el-button>
      </div>
    </el-dialog>

    <!-- 小程序码  -->
    <el-dialog :close-on-click-modal="false" :visible.sync="miniPro.isShow" title="小程序码">
      <div style="text-align: center">
        <img :src="wxQrcode" alt="" style="width: 300px" />
      </div>
    </el-dialog>
    <!-- H5  -->
    <el-dialog :close-on-click-modal="false" :visible.sync="H5.isShow" title="H5码">
      <div style="text-align: center">
        <canvas ref="H5canvas"></canvas>
      </div>
    </el-dialog>
    <!-- 关联工单 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="workOrder.show" :title="workOrder.title" width="40%">
      <el-form ref="workOrderForm" :model="workOrderForm" label-width="120">
        <el-form-item label="关联工单" v-if="isWorkOrder">
          <el-radio-group v-model="workOrderForm.isLink">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="workOrderForm.isLink === 1">
          <el-form-item label="工单选择">
            <el-radio-group v-model="workOrderForm.selectWay" @change="handleSetWorkOrder">
              <el-radio :label="1">手动输入</el-radio>
              <el-radio :label="0">选择</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="工单编号" v-if="workOrderForm.selectWay === 1">
            <el-input v-model.trim="workOrderForm.number" placeholder="请输入工单编号" style="width: 220px">
              <el-button slot="append" icon="el-icon-search" @click="getDetail"></el-button>
            </el-input>
            <p v-if="workOrderForm.businessNameInput">商家名称: {{ workOrderForm.businessNameInput }}</p>
            <p v-if="workOrderForm.saleName">销售: {{ workOrderForm.saleName }}</p>
            <template v-if="commodityList.length">
              <div v-for="item in commodityList" :key="item.id">
                <el-button
                  :type="csWorkOrderId === item.id ? 'primary' : ''"
                  :disabled="!!item.mallId"
                  @click="setCurCsIndex(item)"
                  >{{ item.title }}</el-button
                >
                <span v-if="item.mallId" style="margin: 0 10px">当前商品信息已被关联</span>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="数据展示" v-else>
            <el-table :data="workOrderData" v-loading="loadingOrder" style="width: 100%" height="300">
              <el-table-column align="center" prop="_id" label="工单编号"> </el-table-column>
              <el-table-column align="center" label="商家名称">
                <template slot-scope="{ row }">
                  <p>{{ row.merchant.name }}</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="销售">
                <template slot-scope="{ row }">
                  <p>{{ row.createUser.trueName }}</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商品" min-width="140">
                <template slot-scope="{ row }">
                  <div
                    v-for="item in row.commodityList"
                    :key="item._id"
                    style="display: flex; align-items: center; justify-content: space-between"
                  >
                    <span>{{ item.title }}</span>
                    <el-button
                      v-if="!item.mallId"
                      :type="workOrderId === item._id ? 'primary' : ''"
                      @click="setCurIndex(row, item)"
                      style="margin-bottom: 4px"
                      >选择</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <div
            class="footer"
            v-if="workOrderForm.selectWay === 0 && workOrderData.length > 0"
            style="text-align: center"
          >
            <el-pagination
              background
              :layout="pageLayout"
              :page-sizes="sizeList"
              :total="workdPage.workOrderTotal"
              :page-size="workdPage.workOrderSize"
              :current-page="workdPage.workOrderCurPage"
              @size-change="workOnSizeChange"
              @current-change="workOnPageChange"
            ></el-pagination>
          </div>
        </template>
      </el-form>
      <div slot="footer">
        <el-button round @click="workOrder.show = false">取消</el-button>
        <el-button round type="primary" :loading="btnLoading" @click="confirmWorkOrder">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import tableMixin from '@/mixin/table'
import QRCode from 'qrcode'
export default {
  name: 'normalProductList',
  mixins: [tableMixin],
  data: () => ({
    hideStatus: {
      id: '',
      show: false,
      hideStatus: 1
    },
    download: {
      show: false,
      url: '/boom-center-statistics-service/sysAdmin/exportExl/productPublishExportExcel',
      time: []
      // switchPublish: 1
    },
    miniUrl: '',
    // 删除组件的时间戳
    timestamp: 0,
    promoData: {
      isShow: false,
      wxList: [],
      wx: '',
      url: '',
      productId: '',
      loading: false
    },
    miniPro: {
      isShow: false,
      url: ''
    },
    H5: {
      isShow: false,
      url: ''
    },
    editProductId: '',
    filter: {
      shelfStatus: '',
      stockStatus: '',
      expiringStatus: '',
      productName: '',
      productId: '',
      businessName: '',
      saleType: 5,
      salesStatus: 0,
      businessId: ''
    },
    workOrder: {
      show: false,
      title: '关联工单'
    },
    workOrderForm: {
      id: 0,
      isLink: 1,
      selectWay: 1,
      number: '',
      multiNumber: '',
      businessNameInput: '',
      businessNameSelect: '',
      saleName: ''
    },
    commodityList: [],
    workOrderData: [],
    csWorkOrderId: -1,
    workOrderId: -1,
    wxQrcode: '', //小程序码
    workdPage: {
      workOrderTotal: 0,
      workOrderSize: 10,
      workOrderCurPage: 1
    },
    loadingOrder: false,
    hideProductRange: [],
    currentShowStatus: 0,
    currentSelectProId: 0,
    hideVisible: false,
    selectAreas: [
      {
        name: '金刚区',
        value: 6
      },
      {
        name: '首页商圈',
        value: 7
      },
      {
        name: '商品推荐',
        value: 2
      },
      {
        name: '商家详情页',
        value: 4
      },
      {
        name: '门店详情页',
        value: 5
      },
      {
        name: '分享赚',
        value: 8
      },
      {
        name: '搜索',
        value: 1
      },
      {
        name: '商品详情页的商品推荐',
        value: 3
      }
    ],
    isWorkOrder: false,
    btnLoading: false
  }),
  computed: {
    ...mapGetters({
      currentBusiness: 'business/currentBusiness',
      isExistCurrentBusiness: 'business/isExistCurrentBusiness',
      saleOptions: 'product/partner/saleOptions'
    })
  },
  beforeDestroy() {
    this.clearCurrentBusiness()
    sessionStorage.productId = this.editProductId ? this.editProductId : ''
  },
  methods: {
    handlePromoClick(command, row) {
      console.log(command)
      if (command === 'promo') {
        this.handleShowPromoCode(row)
      } else if (command === 'mini') {
        this.getPageWxQrCode(row)
        this.miniPro.isShow = true
      } else {
        this.handleShowH5Code(row)
      }
    },
    workOnPageChange(e) {
      this.workdPage.workOrderCurPage = e
      this.handleWorkOrderSearch()
    },
    workOnSizeChange(e) {
      this.workdPage.workOrderCurPage = 1
      this.workdPage.workOrderSize = e
      this.handleWorkOrderSearch()
    },
    handleClickBusiness(id) {
      this.$router.push(``)

      this.$router.push({
        path: '/vendor/vendorMange/list',
        query: {
          id: id
        }
      })
    },
    ...mapMutations({ clearCurrentBusiness: 'business/clearCurrentBusiness', updateBasic: 'product/baisc/update' }),
    switchHideStatus(row) {
      this.hideVisible = true
      this.currentShowStatus = row.hideStatus
      this.currentSelectProId = row.id
      this.getHideSettingList(row.id)
    },
    confirmSwitchHideStatus() {
      this.$http
        .post('/boom-center-product-service/sysAdmin/product/reverseHideStatus', {
          id: this.hideStatus.id,
          switchStatus: this.hideStatus.status ? 0 : 1
        })
        .then(res => {
          this.hideStatus.show = false
          this.getData()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },

    batchDeliver() {
      this.$router.push({
        path: '/product/partnerProduct/expressProductList/batchDeliver'
      })
    },

    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/productPublishExportExcel',
        time: []
        // switchPublish: 1
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : ''
            // switchPublish: this.download.switchPublish
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },

    handleShowMiniProgramCode(row) {
      this.miniPro.isShow = true
      this.$nextTick(() => {
        const canvas = this.$refs.canvas

        QRCode.toCanvas(
          canvas,
          `https://${
            this.$store.getters['global/isEnvProd'] ? 'miniapp.egg.tanchi.shop' : 'miniapp.egg.tanchi0757.shop'
          }/product?id=${row.id}&shareId=`,
          function(error) {
            if (error) console.error(error)
            console.log('success!')
          }
        )
      })
    },
    getPageWxQrCode({ id }) {
      //生成页面小程序码
      this.$http
        .post('boom-center-user-service/sysAdmin/wechat/create_mini_app_code_unlimit', {
          page: 'pages/shop/product/index',
          scene: `id=${id}`,
          width: 800
        })
        .then(res => {
          this.wxQrcode = res.codeUrl
        })
    },

    base64ToBlob(urlData, type) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1] || type
      // 去掉url的头，并转化为byte
      let bytes = window.atob(arr[1])
      // 处理异常,将ascii码小于0的转换为大于0
      let ab = new ArrayBuffer(bytes.length)
      // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
      let ia = new Uint8Array(ab)
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], {
        type: mime
      })
    },
    handleShowH5Code(row) {
      this.H5.isShow = true
      this.$nextTick(() => {
        const canvas = this.$refs.H5canvas

        let url
        if (this.$store.getters['global/isEnvDev']) {
          url = 'h5.mall.x.boomsj.com'
        } else if (this.$store.getters['global/isEnvProd']) {
          url = 'h5.mall.tanchi.shop'
        } else {
          url = 'h5.mall.tanchi0757.shop'
        }
        QRCode.toCanvas(canvas, `https://${url}/product/${row.id}/detail?time=${Date.now()}`, function(error) {
          if (error) console.error(error)
          console.log('success!')
        })
      })
    },
    handleAdd() {
      this.isWorkOrder = true
      this.$store.commit('product/partner/updateWriteOffTimeEnd', 0)
      this.$store.commit('product/partner/clearCache')
      this.$store.commit('product/partner/productStoreList', [])

      this.$store.commit('product/partner/updateCache', { key: 'saleType', value: 5 })
      this.workOrder.show = true
      // this.$router.push(`/product/partnerProduct/0/basic?`)
    },
    handleCopy(val) {
      this.$store.commit('product/partner/handlePickBusiness', {
        id: val.businessTitle,
        businessTitle: val.businessTitle,
        businessLogo: ''
      })
      this.$store.commit('product/partner/updateCache', { key: 'saleType', value: 5 })
      this.$router.push(`/product/partnerProduct/${val.id}/basic?copy=1&getdata=1`)
    },
    handleEdit(val) {
      this.editProductId = val.id
      this.$store.commit('product/partner/handlePickBusiness', {
        id: val.businessTitle,
        businessTitle: val.businessTitle,
        businessLogo: ''
      })
      this.$store.commit('product/partner/updateCache', { key: 'saleType', value: 5 })
      this.$router.push(`/product/partnerProduct/${val.id}/basic?cid=${val.oaCommodityId}`)
    },
    getList() {
      return this.$http.get('/homebrew-api/product/logistic', {
        params: {
          ...this.filter,
          ...this.page
        }
      })
    },
    handleReset() {
      this.filter = {
        shelfStatus: '',
        stockStatus: '',
        expiringStatus: '',
        productName: '',
        productId: '',
        businessNameInput: '',
        businessNameSelect: '',
        saleType: 5,
        salesStatus: 0,
        businessId: ''
      }
    },
    CopyUrl() {
      window.getSelection().removeAllRanges()
      const copyDOM = this.$refs.copy
      const range = document.createRange()
      range.selectNode(copyDOM)
      window.getSelection().addRange(range)
      try {
        let successful = document.execCommand('copy')
        console.log(successful)
        this.$message.success('复制成功')
      } catch (err) {
        this.$message.error('复制失败')
      }
      window.getSelection().removeAllRanges()
    },

    successDelete() {
      this.$message.success('删除成功')
      this.init()
    },
    showDelete(row) {
      this.timestamp = parseInt(new Date().getTime())
      sessionStorage.setItem('deleteItemId', row.id)
    },
    onStatusChange(val) {
      let switchPublish = val.switchPublish ? 0 : 1
      this.$api.product
        .product('reverseProductShelfStatus', {
          productId: val.id,
          switchPublish: switchPublish
        })
        .then(res => {
          this.$message.success(`${val.switchPublish === 0 ? '上架' : '下架'} 成功`)
          this.getData()
        })
        .catch(err => {
          this.getData()
          this.$message.error(err.msg || '改变状态失败')
        })
        .finally(() => {})
    },
    handleSkuOrder(id) {
      this.$router.push({
        path: '/order/express',
        query: {
          id: id
        }
      })
    },
    onWxAccountChange(val) {
      this.$log('onWxAccountChange', val)
      this.getWxAccountUrl(val)
    },
    getWxAccountList() {
      return this.$http.get('/boom-center-wechat-service/sysAdmin/wx/media/platform/getAll').then(res => {
        this.promoData.wxList = res
        if (res.length) {
          this.promoData.wx = res[0].mpAppId
        } else {
          this.$message.error('没有公众号')
        }
        return
      })
    },
    getWxAccountUrl(id = this.promoData.wx) {
      console.log(!id)
      if (!id) return
      this.promoData.loading = true
      this.$http
        .get(
          `/boom-center-product-service/wechat/generateWxMpQrCode?appId=${id}&eventKey=${this.promoData.productId}&expireSeconds=2592000`
        )
        .then(res => {
          this.promoData.url = res.url
          this.promoData.isShow = true
          this.promoData.loading = false
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    handleCommand(c, row) {
      switch (c) {
        case 'hidden':
          this.switchHideStatus(row)
          break
        case 'del':
          this.showDelete(row)
          break
        case 'h5':
          this.handleShowH5Code(row)
          break
        case 'subscribe':
          this.handleSubscribe(row)
          break
        case 'workOrder':
          this.handleWorkOrder(row)
          break
        case 'linkToOrder':
          this.handleLinkToOrder(row)
          break
        case 'cancelLinkToOrder':
          this.cancelLinkToOrder(row)
          break
        default:
          break
      }
    },
    cancelLinkToOrder(row) {
      this.$confirm('确定取消关联工单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.loadingOrder = true
        this.$api.product
          .product('unbindIssue', row.id)
          .then(res => {
            this.loadingOrder = false
            this.getData()
            this.$message.success('操作成功')
          })
          .catch(err => {
            this.$message.error(err)
          })
      })
    },
    handleLinkToOrder(row) {
      this.workOrderForm.id = row.id
      this.isWorkOrder = false
      this.workOrderForm.isLink = 1 //关联工单类型
      this.workOrder.show = true
    },
    handleWorkOrder(row) {
      let url = `${process.env.VUE_APP_OA_ORDER_BASE_URL}${row.oaIssueId}`
      window.open(url, '_blank')
    },
    handleSubscribe(row) {
      this.$router.push(`/product/partnerProduct/subscribe?businessId=${row.businessId}&productId=${row.id}`)
    },
    async handleShowPromoCode(val) {
      this.promoData.productId = val.id
      this.miniUrl = `/pages/shop/product/index?id=${val.id}`

      this.promoData.isShow = true
      await this.getWxAccountList()
      this.getWxAccountUrl()
      this.getPageWxQrCode({ id: val.id })
    },
    handleMiniCode(row) {
      this.miniPro.isShow = true
      this.getPageWxQrCode(row)
    },
    handleCheckCode(val) {
      this.$router.push(`/product/partnerProduct/checkCode/checkList?id=${val.checkCodePoolId}`)
    },
    getData() {
      this.loading = true
      this.getList()
        .then(res => {
          this.list = res.list.map(item => {
            let disabled = false
            if (item.areaIds.length > 1 && sessionStorage.currentAreaId != -1) {
              disabled = true
            }
            item.disabled = disabled
            return item
          })
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSetWorkOrder(e) {
      if (e === 0) {
        // 选择
        this.handleWorkOrderSearch()
      }
    },
    // 关联工单
    handleWorkOrderSearch() {
      this.loadingOrder = true
      this.$http
        .get(`/boom-center-product-service/sysAdmin/oa/findIssuePage`, {
          params: {
            page: this.workdPage.workOrderCurPage,
            size: this.workdPage.workOrderSize
          }
        })
        .then(res => {
          this.loadingOrder = false
          this.workOrderData = res.list
          this.workdPage.workOrderTotal = res.total
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    confirmWorkOrder() {
      let businessName = this.workOrderForm.businessNameInput || this.workOrderForm.businessNameSelect
      let id
      if (this.workOrderForm.isLink) {
        if (!businessName) {
          if (this.workOrderForm.selectWay === 1) {
            this.$message.error('请输入工单编号')
          } else {
            this.$message.error('请选择工单编号')
          }
          return
        }
        if (
          (this.workOrderForm.selectWay === 1 && this.csWorkOrderId === -1) ||
          (this.workOrderForm.selectWay === 0 && this.workOrderId === -1)
        ) {
          this.$message.error('请选择商品')
          return
        }
        id = this.workOrderForm.selectWay === 1 ? this.csWorkOrderId : this.workOrderId
        // 没有关联工单的商品进行工单关联
        if (!this.isWorkOrder) {
          this.btnLoading = true
          this.$http
            .put('/boom-center-product-service/sysAdmin/product/bindIssue', {
              oaCommodityId: id,
              oaIssueId: this.workOrderForm.multiNumber,
              productId: this.workOrderForm.id
            })
            .then(res => {
              this.$message.success('操作成功')
              this.getData()
              this.resetData()
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              this.btnLoading = false
            })
          return
        }
        this.$router.push(
          `/product/partnerProduct/0/basic?id=${this.workOrderForm.multiNumber}&cid=${id}&businessName=${businessName}`
        )
        this.resetData()
      } else {
        this.$router.push(`/product/partnerProduct/0/basic`)
      }
      this.workOrder.show = false
    },
    resetData() {
      this.commodityList = []
      this.workOrderForm = {
        isLink: 1,
        selectWay: 1,
        number: '',
        multiNumber: '',
        businessNameInput: '',
        businessNameSelect: '',
        saleName: ''
      }
      this.workOrderId = -1
      this.csWorkOrderId = -1
      this.workOrder.show = false
    },
    setCurIndex(row, item) {
      this.workOrderId = item._id //设置商品id
      this.workOrderForm.multiNumber = row._id //设置工单id
      this.workOrderForm.businessNameSelect = row.merchant.name
    },
    setCurCsIndex(row) {
      this.csWorkOrderId = row.id //设置商品id
    },
    getDetail() {
      this.$http
        .get('/boom-center-product-service/sysAdmin/oa/findIssueDetail', {
          params: {
            issueId: this.workOrderForm.number
          }
        })
        .then(res => {
          let { issue, commodityList } = res
          this.workOrderForm.multiNumber = issue._id
          this.workOrderForm.businessNameInput = issue.merchant.name
          console.log(issue.userList.map(item => item.role === '销售'))
          this.workOrderForm.saleName =
            issue.userList.find(item => item.role === '销售') &&
            issue.userList.find(item => item.role === '销售').user.trueName
          this.commodityList = commodityList.map(item => ({
            mallId: item.mallId,
            title: item.title,
            id: item._id
          }))
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getHideSettingList(id) {
      this.$api.product
        .product('findHideSettingList', id)
        .then(res => {
          this.hideProductRange = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleProductArea() {
      this.$api.product
        .product('hideSetting', {
          id: this.currentSelectProId,
          hideSettingList: this.hideProductRange
        })
        .then(res => {
          this.$message.success('修改成功')
          this.hideVisible = false
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  },

  created() {},

  activated() {
    if (this.isExistCurrentBusiness) {
      this.filter.businessId = this.currentBusiness.id
      const that = this
      setTimeout(() => {
        that.handleSearch()
      }, 500)
    } else {
      const that = this
      setTimeout(() => {
        that.getData()
      }, 500)
    }
  },
  deactivated() {
    this.clearCurrentBusiness()
    this.filter.businessId = ''
    sessionStorage.productId = this.editProductId ? this.editProductId : ''
  },
  mounted() {
    if (this.$route.query.paramsId) {
      this.filter.salesStatus = parseInt(this.$route.query.paramsId)
      const that = this
      setTimeout(() => {
        that.handleSearch()
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}

/deep/ .el-table__expand-icon {
  position: relative;
  cursor: pointer;
  color: #666;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
  height: 30px;
  font-size: 20px;
}

.miniUrl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 40px;
  img,
  div {
    margin: 10px 0;
  }
}
.vendorInfo {
  cursor: pointer;
  text-align: start;
}
.vendorInfo:hover {
  color: #1975ff;
}
.skuContainer {
  display: flex;
  .info {
    flex-grow: 1;
    display: flex;
    margin-right: 5px;
  }
  .skuName {
    width: 120px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.circle-colorsuperA {
  background: #9fdf6a;
}
.circle-colorA {
  background: #a2cfbf;
}
.circle-colorB {
  background: #ec9ef3;
}
.circle-colorC {
  background: #f0b754;
}
.circle-colorD {
  background: #f7603e;
}
.circle-color {
  color: black;
}
.circle-color- {
  color: black;
}
.productName {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: start;
  cursor: pointer;
}
</style>
